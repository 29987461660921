import React from 'react';
import './technologieItem.css';

export default function TechnologieItem(props){
    return(
        <div className="technologieItem" onClick={()=>window.open(props.url, '_blank')}>
            <img className="technologieItemImage" src={props.src} alt={props.alt}/>
            <div className="labelTechnologieItem">{props.alt}</div>
        </div>
    );
}