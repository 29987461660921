import React from 'react';
import './application.css';
import ApplicationItem from './ApplicationItem/ApplicationItem';
import logoCencoreBon from "../../media/logoCencoreBon.png";
import logoRHC from "../../media/RHClogo.png";

export default function Applications(){
    const applications = [ 
        { src:logoCencoreBon, alt:"C'encore bon?", link:"https://play.google.com/store/apps/details?id=com.mc.cencorebongratuit"}
    ];
    return(
        <>
        <div id='application'>
            <h1 className="titre">Applications</h1>
            <div id="tableauApplis">
                {applications.map((elm, i)=>{
                    return(
                        <ApplicationItem src={elm.src} alt={elm.alt} url={ elm.link }/>
                    );
                })}
            </div>
        </div>
        <div className="spacerFooter"></div>
        </>
    );
}
