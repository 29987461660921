import React from 'react';
import './head.css';
import logo from '../../media/logoNB.png';

export default function Head(){
    return(
        <div id='head'>
            <img id='logo' src={logo} alt="Logo de Maxence Cropat"/>
            
        </div>
    );
}