import React from 'react';
import './technologies.css';
import TechnologieItem from './TechnologieItem/TechnologieItem';
import logoReact from "../../media/logoReact.svg";
import logoExpo from "../../media/logoExpo.png";
import logoStrapi from "../../media/strapi-1-e1609834851191.png";
import logoAdMob from "../../media/google-admob-logo.png";
import logoMySql from "../../media/MySQL-Logo.wine.png";
import logoJS from "../../media/JS.png";
import logoJava from "../../media/java.png";
import logoCPP from "../../media/c++.png";
import logoPython from "../../media/python.png";
import logoMJ from "../../media/Mailjet-Yellow.png";
import logoGit from "../../media/git.png";
import logoCss from "../../media/CSS3_logo_and_wordmark.svg.png";
import logoHtml from "../../media/1200px-HTML5_logo_and_wordmark.svg.png";

export default function Technologies(){
    let technologies = [
        { src:logoReact, alt:'React', link:"https://fr.reactjs.org/"},
        { src:logoReact, alt:'React Native', link:"https://reactnative.dev/"},
        { src:logoExpo, alt:'Expo', link:"https://expo.dev/"},
        { src: logoStrapi, alt:'Strapi', link:'https://strapi.io/'},
        { src: logoAdMob, alt:'AdMob', link:'https://admob.google.com/intl/fr/home/'},
        { src: logoMySql, alt:'MySQL', link:'https://www.mysql.com/fr/'},
        { src: logoJS, alt:'JavaScript', link:'https://www.javascript.com/'},
        { src: logoJava, alt:'Java', link:'https://www.java.com/fr/'},
        { src: logoCPP, alt:'C++', link:'https://isocpp.org/'},
        { src: logoPython, alt:'Python', link:'https://www.python.org/'},
        { src: logoMJ, alt:'Mailjet', link:'https://fr.mailjet.com/'},
        { src: logoGit, alt:'Github', link:'https://github.com/'},
        { src: logoCss, alt:'CSS', link:'https://www.w3.org/Style/CSS/Overview.fr.html'},
        { src: logoHtml, alt:'HTML', link:'https://www.w3schools.com/html/'},

    ];
    technologies.sort((a, b) => 0.5 - Math.random());
    return(
        <>
            <div class="spacerTop">
            </div>
            <div id='technologie'>
                <h1 className="titre">Technologies</h1>
                <div id="tableauTechno">
                    {technologies.map((elm, i)=>{
                        return(
                            <TechnologieItem src={elm.src} alt={elm.alt} url={ elm.link }/>
                        );
                    })}
                </div>
            </div>
            <div className="spacer">

            </div>
        </>
    );
}