import React from 'react';
import './applicationItem.css';

export default function ApplicationItem(props){
    return(
        <div className="applicationItem" onClick={()=>window.open(props.url, '_blank')}>
            <img className="applicationItemImage" src={props.src} alt={props.alt}/>
            <div className="labelApplicationItem">{props.alt}</div>
        </div>
    );
}