import './App.css';
import React, { Suspense, useEffect } from 'react';
import Head from "./class/Head/Head";
import Technologies from "./class/Technologies/Technologies";
import Applications from './class/Applications/Application';
import Footer from './class/Footer/Footer';
import './style.css';


function App() {
    let blob = [];
    for(let i = 0; i<17; i++){
        blob.push(<div className="blob"/>)
    }
    return (
        <div className="App">
            <Head />
            <Technologies />
            <Applications />
            <Footer />
            <div className="blobContainer">
            { blob }
            </div>
        </div>
    );
}

export default App;
