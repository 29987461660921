import "./footer.css";
import React, { Component }  from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpen, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

export default function Footer(){
    return(
        <>
        <div id='footer'>
            <div id='contact'>
                <div className='contactItem'>
                    <FontAwesomeIcon icon={faEnvelopeOpen} size='3x' />
                    <a className="labelContact" href="mailto:contact@maxence-cropat.com"> contact@maxence-cropat.com</a>
                </div>
                <div className='contactItem'>
                    <FontAwesomeIcon icon={faPhone} size='3x' />
                    <a className="labelContact" href="tel:+33612496832"> 06.12.49.68.32</a>
                </div>
                <div className='contactItem'>
                    <FontAwesomeIcon icon={faInstagram} size='3x' />
                    <a className="labelContact" href="https://www.instagram.com/mc_developpement/?hl=fr"> mc_developpement</a>
                </div>
            </div>
            <a id="mentionsLegales" href='https://www.maxence-cropat.com/mentions-legales.pdf' target="_blank" rel="noreferrer">Mentions légales</a>
        </div>
        </>
    );
}

